<template>
  <b10-base>
    <!-- datos estáticos de material de sistema -->
    <v-text-field
      v-model="form.descripcion"
      clearable
      label="Descripción"
    />
    <v-text-field
      v-model.number="form.unidades"
      label="Unidades"
      clearable
      type="number"
      :rules="formRules.unidades"
    />
    <b10-date-picker
      v-model="form.fsistema"
      title="Instalación"
      :rules="formRules.fsistema"
      @change="changeFsistema"
    />
    <b10-autocomplete
      v-model="form.idtimportancia_material"
      :items="tipoImportanciaMaterial"
      item-value="timportancia_material.idtimportancia_material"
      item-text="timportancia_material.descripcion"
      label="Importancia"
      clearable
    />
    <v-text-field
      v-if="tsubsis.ver_mat_nserie"
      v-model="form.nserie"
      clearable
      label="Nº serie"
      :append-outer-icon="$vuetify.icons.values.camera"
      @click:append-outer="clickNserieQR"
    />
    <v-text-field
      v-if="tsubsis.ver_mat_nelemento"
      v-model="form.nelemento"
      clearable
      label="Nº elemento"
    />
    <v-text-field
      v-if="tsubsis.ver_mat_ubicacion"
      v-model="form.ubicacion"
      clearable
      label="Ubicación"
    />
    <v-text-field
      v-if="tsubsis.ver_mat_netiqueta"
      v-model="form.netiqueta"
      clearable
      label="Nº etiqueta"
      :append-outer-icon="$vuetify.icons.values.camera"
      @click:append-outer="clickNetiquetaQR"
    />
    <v-text-field
      v-if="tsubsis.ver_mat_num_zona"
      v-model="form.num_zona"
      clearable
      :rules="formRules.num_zona"
      label="Nº zona"
    />
    <v-text-field
      v-if="tsubsis.ver_mat_evento"
      v-model="form.evento"
      clearable
      label="Evento"
    />
    <v-text-field
      v-if="tsubsis.ver_mat_funcion"
      v-model="form.funcion"
      clearable
      label="Función"
    />
    <v-text-field
      v-if="tsubsis.ver_mat_particion"
      v-model="form.particion"
      clearable
      label="Partición"
    />
    <!-- fultrevision (no debe ser editable) -->
    <!-- fprox_revision (no existe aún en offline, es un campo calculado en el servidor) -->
    <b10-date-picker
      v-if="tsubsis.ver_mat_fult_retimbrado"
      v-model="form.fult_retimbrado"
      title="Último retimbrado"
    />
    <!-- fprox_retimbrado (no existe aún en offline, es un campo calculado en el servidor) -->
    <b10-date-picker
      v-if="tsubsis.ver_mat_fcaducidad"
      v-model="form.fcaducidad"
      title="Caducidad"
    />
    <b10-date-picker
      v-if="tsubsis.ver_mat_ffin_garantia"
      v-model="form.ffin_garantia"
      title="Fin de garantía"
    />
    <b10-autocomplete
      v-if="tsubsis.ver_mat_idfabricante"
      v-model="form.idfabricante"
      :items="fabricantes"
      item-value="fabricante.idfabricante"
      item-text="fabricante.descripcion"
      label="Fabricante"
      clearable
    />
    <b10-date-picker
      v-if="tsubsis.ver_mat_ffabricacion"
      v-model="form.ffabricacion"
      title="Fabricación"
      @change="changeFfabricacion"
    />
    <b10-autocomplete
      v-if="tsubsis.ver_mat_idagente_extintor"
      v-model="form.idagente_extintor"
      :items="agentesExtintores"
      item-value="agente_extintor.idagente_extintor"
      item-text="agente_extintor.descripcion"
      label="Agente extintor"
      clearable
    />
    <b10-autocomplete
      v-if="tsubsis.ver_mat_idagente_propelente"
      v-model="form.idagente_propelente"
      :items="agentesPropelentes"
      item-value="agente_propelente.idagente_propelente"
      item-text="agente_propelente.descripcion"
      label="Agente propelente"
      clearable
    />
    <v-text-field
      v-if="tsubsis.ver_mat_volumen"
      v-model.number="form.volumen"
      clearable
      label="Volumen"
      type="Number"
    />
    <v-text-field
      v-if="tsubsis.ver_mat_peso_total"
      v-model.number="form.peso_total"
      clearable
      label="Peso total"
      type="Number"
    />
    <v-text-field
      v-if="tsubsis.ver_mat_peso_agente_extintor"
      v-model.number="form.peso_agente_extintor"
      clearable
      label="Peso agente extintor"
      type="Number"
    />
    <v-text-field
      v-if="tsubsis.ver_mat_eficacia"
      v-model="form.eficacia"
      clearable
      label="Eficacia"
    />
    <v-text-field
      v-if="tsubsis.ver_mat_presion"
      v-model.number="form.presion"
      clearable
      label="Presión"
      type="Number"
    />
    <!-- datos dinámicos de ficha técnica -> TDATO_ARTICULO -->
    <div
      v-for="item in form.datosArticulo"
      :key="item.idtdato_articulo"
    >
      <b10-autocomplete
        v-if="item.tipo_valor === tiposTdatoArticulo.tipos.seleccion"
        v-model="item.valor"
        :items="item.valores"
        item-value="valor"
        item-text="valor"
        :label="item.descripcion"
        clearable
      />
      <v-text-field
        v-if="item.tipo_valor === tiposTdatoArticulo.tipos.numero || item.tipo_valor === tiposTdatoArticulo.tipos.texto"
        v-model="item.valor"
        clearable
        :label="item.descripcion"
      />
      <b10-date-picker
        v-if="item.tipo_valor === tiposTdatoArticulo.tipos.fecha"
        v-model="item.valor"
        clearable
        :title="item.descripcion"
      />
    </div>
    <b10-textarea
      v-model="form.observaciones"
      label="Observaciones"
    />
    <b10-textarea
      v-model="form.observaciones_internas"
      label="Observaciones internas"
    />
    <b10-qr-reader
      :showing.sync="showingQR"
      title="Leer código QR"
      @decode="decodeQR"
    />
  </b10-base>
</template>

<script>
import formMixin from '@/mixins/formMixin'
import _ from '@/utils/lodash'
import Data from './MaterialSistemaFormData'
import { TDATO_ARTICULO } from '@/utils/consts'
import { toDate, addMonths, firstDayOfMonth, isValidDate } from '@/utils/date'

export default {
  mixins: [formMixin],
  props: {
    mesesCaducidad: {
      type: Number,
      default: 0,
    },
    mesesGarantia: {
      type: Number,
      default: 0,
    },
  },
  data () {
    return {
      form: {
        descripcion: null,
        unidades: null,
        fsistema: null,
        nserie: null,
        nelemento: null,
        ubicacion: null,
        netiqueta: null,
        num_zona: null,
        evento: null,
        funcion: null,
        particion: null,
        idfabricante: null,
        ffabricacion: null,
        idagente_extintor: null,
        idagente_propelente: null,
        volumen: null,
        peso_total: null,
        peso_agente_extintor: null,
        eficacia: null,
        presion: null,
        // fultrevision: null, (no debe ser editable)
        // fprox_revision: null, (no existe aún en offline, es un campo calculado en el servidor)
        fult_retimbrado: null,
        // fprox_retimbrado: null, (no existe aún en offline, es un campo calculado en el servidor)
        fcaducidad: null,
        ffin_garantia: null,
        idtimportancia_material: null,
        datosArticulo: [],
        observaciones: null,
        observaciones_internas: null,
      },
      fabricantes: [],
      agentesExtintores: [],
      agentesPropelentes: [],
      tsubsis: [],
      tipoImportanciaMaterial: [],
      tiposTdatoArticulo: TDATO_ARTICULO,
      formRules: {
        unidades: [
          v => !!v || 'Campo requerido'
        ],
        fsistema: [
          v => !!v || 'Campo requerido'
        ],
        num_zona: [
          v => (v || '').length <= 10 || `La longitud máxima es de 10 caracteres`,
        ],
      },
      showingQR: false,
      targetFieldQR: '',
    }
  },
  async created () {
    this.fabricantes = await Data.selectFabricantes(this)
    this.agentesExtintores = await Data.selectAgentesExtintores(this)
    this.agentesPropelentes = await Data.selectAgentesPropelentes(this)
    this.tipoImportanciaMaterial = await Data.selectTipoImportanciaMaterial(this)
    await this.loadForm()
  },
  methods: {
    async loadFormData (id) {
      this.tsubsis = await Data.selectTsubsis(this, id)
      const formData = await Data.selectFormData(this, id)
      this.$set(this.form, 'descripcion', formData.material_sistema.descripcion)
      this.$set(this.form, 'unidades', formData.material_sistema.unidades)
      this.$set(this.form, 'fsistema', formData.material_sistema.fsistema)
      this.$set(this.form, 'nserie', formData.material_sistema.nserie)
      this.$set(this.form, 'nelemento', formData.material_sistema.nelemento)
      this.$set(this.form, 'ubicacion', formData.material_sistema.ubicacion)
      this.$set(this.form, 'netiqueta', formData.material_sistema.netiqueta)
      this.$set(this.form, 'num_zona', formData.material_sistema.num_zona)
      this.$set(this.form, 'evento', formData.material_sistema.evento)
      this.$set(this.form, 'funcion', formData.material_sistema.funcion)
      this.$set(this.form, 'particion', formData.material_sistema.particion)
      this.$set(this.form, 'idfabricante', formData.material_sistema.idfabricante)
      this.$set(this.form, 'ffabricacion', formData.material_sistema.ffabricacion)
      this.$set(this.form, 'idagente_extintor', formData.material_sistema.idagente_extintor)
      this.$set(this.form, 'idagente_propelente', formData.material_sistema.idagente_propelente)
      this.$set(this.form, 'volumen', formData.material_sistema.volumen)
      this.$set(this.form, 'peso_total', formData.material_sistema.peso_total)
      this.$set(this.form, 'peso_agente_extintor', formData.material_sistema.peso_agente_extintor)
      this.$set(this.form, 'eficacia', formData.material_sistema.eficacia)
      this.$set(this.form, 'presion', formData.material_sistema.presion)
      // this.form.fultrevision (no debe ser editable)
      // fprox_revision (no existe aún en offline, es un campo calculado en el servidor)
      this.$set(this.form, 'fult_retimbrado', formData.material_sistema.fult_retimbrado)
      // fprox_retimbrado (no existe aún en offline, es un campo calculado en el servidor)
      this.$set(this.form, 'fcaducidad', formData.material_sistema.fcaducidad)
      this.$set(this.form, 'ffin_garantia', formData.material_sistema.ffin_garantia)
      this.$set(this.form, 'idtimportancia_material', formData.material_sistema.idtimportancia_material)
      this.$set(this.form, 'observaciones', formData.material_sistema.observaciones)
      this.$set(this.form, 'observaciones_internas', formData.material_sistema.observaciones_internas)
      // datos dinámicos de FICHA_TECNICA -> DATO_MATERIAL_SISTEMA
      this.$set(this.form, 'datosArticulo', [])
      const tdatosArticulo = await Data.selectTDatoArticulo(this, formData.articulo.idficha_tecnica)
      for (let tdato of tdatosArticulo) {
        // recuperar valores de DATO_MATERIAL_SISTEMA
        const datoMaterialSistema = await Data.selectDatoMaterialSistemaValor(
          this, this.id, tdato.tdato_articulo.idtdato_articulo
        )
        const indexDatoArticulo = this.form.datosArticulo.push({
          descripcion: tdato.tdato_articulo.descripcion,
          idtdato_articulo: tdato.tdato_articulo.idtdato_articulo,
          tipo_valor: tdato.tdato_articulo.tipo_valor,
          valor: (
            datoMaterialSistema[0] ? datoMaterialSistema[0].dato_material_sistema.valor : ''
          ),
          iddato_material_sistema: (
            datoMaterialSistema[0] ? datoMaterialSistema[0].dato_material_sistema.iddato_material_sistema : null
          ),
          valores: []
        })
        // recuperar valores de TDATO_ARTICULO_VALOR (si es un select)
        if (tdato.tdato_articulo.tipo_valor === TDATO_ARTICULO.tipos.seleccion) {
          const tdatoArticuloValor = await Data.selectTDatoArticuloValor(
            this, tdato.tdato_articulo.idtdato_articulo
          )
          this.form.datosArticulo[indexDatoArticulo - 1].valores.push(
            ..._.map(tdatoArticuloValor, 'tdato_articulo_valor.valor')
          )
        }
        // si es date y no tiene formato correcto almacenado
        if (
          tdato.tdato_articulo.tipo_valor === TDATO_ARTICULO.tipos.fecha &&
          this.form.datosArticulo[indexDatoArticulo - 1].valor
        ) {
          const valorDate = toDate(this.form.datosArticulo[indexDatoArticulo - 1].valor)
          // si no es un día válido (Invalid Date)
          if (!isValidDate(valorDate)) {
            this.form.datosArticulo[indexDatoArticulo - 1].tipo_valor = TDATO_ARTICULO.tipos.texto
          }
        }
      }
    },
    changeFfabricacion (value) {
      if (this.mesesCaducidad > 0) {
        this.$set(this.form, 'fcaducidad', addMonths(firstDayOfMonth(toDate(value)), this.mesesCaducidad))
      }
    },
    changeFsistema (value) {
      if (this.mesesGarantia > 0) {
        this.$set(this.form, 'ffin_garantia', addMonths(toDate(value), this.mesesGarantia))
      }
    },
    clickNetiquetaQR () {
      this.targetFieldQR = 'netiqueta'
      this.showingQR = !this.showingQR
    },
    clickNserieQR () {
      this.targetFieldQR = 'nserie'
      this.showingQR = !this.showingQR
    },
    async decodeQR (value) {
      this.$set(this.form, this.targetFieldQR, value)
    },
  },
}
</script>

